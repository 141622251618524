import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'ui-components-web';
import { IconSet } from 'ui-components-web/src/Components/Icon';
import BankLogo from 'Assets/Volksbanken_logo.svg';
import Menu from 'Components/Menu';
import theme from 'Global/theme';
import { CustomEvents } from 'Global/types';
import { trackEvent } from 'Global/utils';
import { IconContainer, MenuHeaderContainer } from './style';
function Header({ setModalOpen }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const handleLogoClick = () => navigate('/overview');
    const handleMenuClick = () => {
        isMenuOpen && trackEvent(CustomEvents.BURGER_MENU_CLICK);
        setIsMenuOpen(!isMenuOpen);
    };
    return (_jsxs(MenuHeaderContainer, { children: [_jsx("img", { onClick: handleLogoClick, src: BankLogo, style: { cursor: 'pointer' } }), _jsx(IconContainer, { children: _jsx(Icon, { onClick: handleMenuClick, iconName: IconSet.burger, color: theme.colors.white, iconSize: 25 }) }), isMenuOpen && _jsx(Menu, { callback: handleMenuClick, setModalOpen: setModalOpen })] }));
}
export default Header;
