import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CompanyData from 'Components/CompanyData';
import ContentHeader from 'Components/CustomerDetailsHeader';
import LiquidityChart from 'Components/LiquidityChart';
import LiquidityStatus from 'Components/LiquidityStatus';
import RecomendationsList from 'Components/RecomendationsList';
import useTimeOnPage from 'Global/hooks/useTimeOnPage';
import { PanelHeading } from 'Global/styled';
import { formatDate } from 'Global/utils';
import { getCustomerDetails, getRecommendations } from 'Service/api/finInsights';
import { BlockWrapper, ChartBlockWrapper, MainContentWrapper, PageContentWrapper, RightColumnContentWrapper, } from './style';
function CustomerDetails() {
    const { sme_tenant_id, sme_identity_id } = useParams();
    useTimeOnPage('CustomerDetails');
    const [data, setData] = useState();
    const [recomendationsItems, setRecomendationsItems] = useState();
    // const [eventsData, setEventsData] = useState()
    const [refresh, setRefresh] = useState(false);
    // const [comments, setComments] = useState()
    useEffect(() => {
        sme_tenant_id &&
            sme_identity_id &&
            getCustomerDetails(sme_tenant_id, sme_identity_id)
                .then(({ data }) => {
                setData(data);
                setRefresh(false);
            })
                .catch((error) => console.log({ error }));
    }, [sme_tenant_id, sme_identity_id, refresh]);
    useEffect(() => {
        sme_tenant_id &&
            sme_identity_id &&
            getRecommendations(sme_tenant_id, sme_identity_id)
                .then(({ data }) => {
                const { recommendations } = data;
                setRecomendationsItems(recommendations);
            })
                .catch((error) => console.log({ error }));
    }, [sme_tenant_id, sme_identity_id, refresh]);
    // useEffect(() => {
    //   sme_tenant_id &&
    //     getCustomerEvents(sme_tenant_id)
    //       .then(({ data }: AxiosResponse<any, RecomendationRowType[]>) => {
    //         const { events, status_history } = data
    //         const translatedNotes = status_history.map((item: any) => ({
    //           ...item,
    //           description: ActionOptions.find((status) => status.value === item.status)?.label,
    //         }))
    //         setComments(events)
    //         setEventsData(translatedNotes.reverse())
    //       })
    //       .catch((error) => console.log({ error }))
    // }, [sme_tenant_id, refresh])
    // const addNewComment = useCallback(
    //   (value: string) =>
    //     sme_tenant_id && addCustomerEvent(sme_tenant_id, value).then((result) => setRefresh(true)),
    //   [],
    // )
    // const handleUpdateStatus = useCallback(
    //   (value: any) =>
    //     sme_tenant_id &&
    //     updateCustomerStatus(sme_tenant_id, value.value)
    //       .then((result) => setRefresh(true))
    //       .catch((error) => console.log({ error })),
    //   [],
    // )
    // const handleDeleteNote = useCallback(
    //   (value: any) =>
    //     sme_tenant_id &&
    //     deleteCustomerEvent(value)
    //       .then((result) => setRefresh(true))
    //       .catch((error) => console.log({ error })),
    //   [],
    // )
    if (!data)
        return _jsx(_Fragment, {});
    const { sme_data, liquidity = { balances: { overall: {} } },
    // revenue,
    // cost,
    // status,
    // liabilities,
    // assets,
    // benchmarking_insights,
     } = data;
    const companyAddress = sme_data?.identification?.company_address;
    // const renderColumns = [
    //   {
    //     title: 'Kennzahl',
    //     dataIndex: 'key',
    //     key: 'key',
    //     render: (text: string) =>
    //       t(`CustomerDetails.IndustryComparisonKeys.${text}`, { defaultValue: text }),
    //   },
    //   {
    //     title: 'Branchendurchschnitt',
    //     dataIndex: 'actual_value',
    //     key: 'actual_value',
    //     render: (
    //       value: number,
    //       record: GetCustomerDetailsResponse['benchmarking_insights']['industry_averages']['some_key'],
    //     ) => {
    //       if (record?.unit === 'EUR') {
    //         return formatCurrency(value)
    //       }
    //       if (record?.unit === '%') {
    //         return `${(value * 100).toFixed(1)}%`
    //       }
    //       return value
    //     },
    //   },
    //   {
    //     title: 'Einschätzung',
    //     dataIndex: 'benchmark_value',
    //     key: 'benchmark_value',
    //     render: (
    //       value: number,
    //       record: GetCustomerDetailsResponse['benchmarking_insights']['industry_averages']['some_key'],
    //     ) => {
    //       if (record?.unit === 'EUR') {
    //         return formatCurrency(value)
    //       }
    //       if (record?.unit === '%') {
    //         return `${(value * 100).toFixed(1)}%`
    //       }
    //       return value
    //     },
    //   },
    //   {
    //     title: 'Status',
    //     dataIndex: 'comparison',
    //     key: 'comparison',
    //     render: (record: string) => (
    //       <Tag color={record?.toUpperCase() === 'POSITIVE' ? 'green' : 'gold'}>
    //         {record?.toUpperCase() === 'POSITIVE' ? 'Positiv' : 'Verbesserungswürdig'}
    //       </Tag>
    //     ),
    //   },
    // ]
    // const dataSource =
    //   benchmarking_insights?.industry_averages &&
    //   Object.keys(benchmarking_insights?.industry_averages).map((key: any) => ({
    //     key: key,
    //     comparison: benchmarking_insights.industry_averages[key]?.comparison,
    //     actual_value: benchmarking_insights.industry_averages[key]?.actual_value,
    //     benchmark_value: benchmarking_insights.industry_averages[key]?.benchmark_value,
    //     unit: benchmarking_insights.industry_averages[key]?.unit,
    //   }))
    const overall = liquidity?.balances?.overall;
    const liquidityStatus = overall?.liquidity_status;
    return (_jsxs("div", { style: { paddingBottom: '10em' }, children: [_jsx(ContentHeader, { title: sme_data?.identification?.company_name ?? '', description: sme_data?.main_industry_segment?.industry_segment_name_de ?? '' }), _jsxs(PageContentWrapper, { children: [_jsxs(MainContentWrapper, { "$isMultipleChildren": !!recomendationsItems, children: [_jsx(PanelHeading, { children: "Unternehmensinformationen" }), _jsx("div", { style: { marginTop: '1rem' }, children: _jsx(CompanyData, { phone: sme_data?.stakeholder?.phone_number, street: companyAddress?.street, city: companyAddress?.city, postalCode: companyAddress?.postcode, name: [sme_data?.stakeholder?.firstname, sme_data?.stakeholder?.lastname]
                                        .filter(Boolean)
                                        .join(' '), dateOfEstablishment: formatDate(sme_data?.identification?.foundation_date), legalForm: sme_data?.identification?.legal_form, companyName: sme_data?.identification?.company_name, taxNumber: sme_data?.identification?.ustd_id }) }), _jsxs("div", { style: { marginTop: '2rem' }, children: [_jsx(PanelHeading, { children: t('CustomerDetails.FinancialFigures') }), _jsx(BlockWrapper, { "$isMultipleChildren": false, children: liquidityStatus && (_jsx(ChartBlockWrapper, { children: _jsx(LiquidityStatus, { status: liquidityStatus, description: t(`CustomerDetails.LiquidityStatusDescription.${liquidityStatus}`), title: t('CustomerDetails.LiquidityStatus') }) })) })] }), liquidity ? (_jsx("div", { style: { marginTop: '1rem' }, children: _jsx(LiquidityChart, { overall: liquidity, status: liquidityStatus }) })) : null] }), _jsx(RightColumnContentWrapper, { children: recomendationsItems ? _jsx(RecomendationsList, { items: recomendationsItems }) : null })] })] }));
}
export default CustomerDetails;
