import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { CustomList } from 'ui-components-web';
import renderPendingInvitationsColumns from 'Components/TableRow/renderPendingInvitationsColumns';
import { getPendingInvitations } from 'Service/api/finInsights';
function InvitationsTab({ filter }) {
    const [filteredData, setFilteredData] = useState();
    const [pendingInvitations, setPendingInvitations] = useState([]);
    const fetchData = useCallback(async () => {
        try {
            const { data } = await getPendingInvitations();
            data && setPendingInvitations(data);
        }
        catch (error) {
            console.log({ error });
        }
    }, [setPendingInvitations]);
    useEffect(() => {
        fetchData();
    }, [fetchData]);
    useEffect(() => {
        if (filter) {
            const filtered = (pendingInvitations || []).filter((item) => {
                return ([item.first_name, item.last_name, item.email]
                    .join(' ')
                    .toLowerCase()
                    .indexOf(filter.toLowerCase()) >= 0);
            });
            setFilteredData(filtered);
        }
        else {
            setFilteredData(pendingInvitations);
        }
    }, [pendingInvitations, filter]);
    return (_jsx(CustomList, { data: filteredData, title: '', columns: renderPendingInvitationsColumns(), sorterText: t('OverviewPage.SortText'), tableHead: _jsx(_Fragment, {}) }));
}
export default InvitationsTab;
