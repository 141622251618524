import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import { parseAsInteger, useQueryState } from 'nuqs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomList } from 'ui-components-web';
import Pagination from 'Components/Pagination';
import renderFinCheckUsersColumns from 'Components/TableRow/renderFinCheckUsersColumns';
import { PaginationContainer } from 'Pages/style';
import ROUTES from 'Router/routes';
import { getCustomers } from 'Service/api/finInsights';
function UsersTab({ filter }) {
    const navigate = useNavigate();
    const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
    const [totalItems, setTotalItems] = useState(1);
    const [customersList, setCustomersList] = useState([]);
    const handleNavigateRow = (sme_tenant_id, sme_identity_id) => {
        navigate(ROUTES.CUSTOMER_DETAILS + '/' + sme_tenant_id + '/' + sme_identity_id);
    };
    // const handleScroll = useCallback(() => {
    //   const scrollPosition = window.innerHeight + document.documentElement.scrollTop
    //   const bottomPosition = document.documentElement.offsetHeight
    //   if (scrollPosition >= bottomPosition && nextPage !== null) {
    //     fetchData()
    //   }
    // }, [setCurrentPage, setNextPage])
    useEffect(() => {
        getCustomers(page, filter)
            .then((response) => {
            const { data, page, total_items } = response.data;
            setPage(page);
            setTotalItems(total_items);
            setCustomersList(data);
        })
            .catch((error) => {
            console.error('Failed to fetch data:', error);
        });
    }, [filter, page, setTotalItems]);
    // useEffect(() => {
    //   if (filter) {
    //     const filtered = (customersList || []).filter((item) => {
    //       return (
    //         [
    //           item.company_name,
    //           t(`OverviewPage.FinCheckStatus.${item.fincheck_status}`),
    //           item.owner_name,
    //         ]
    //           .join(' ')
    //           .toLowerCase()
    //           .indexOf(filter.toLowerCase()) >= 0
    //       )
    //     })
    //     setFilteredData(filtered)
    //   } else {
    //     setFilteredData(customersList)
    //   }
    // }, [customersList, filter])
    return (_jsxs(_Fragment, { children: [_jsx(CustomList, { onRowClick: (_, record) => handleNavigateRow(record.sme_tenant_id, record.sme_identity_id), data: customersList, title: '', columns: renderFinCheckUsersColumns(), sorterText: t('OverviewPage.SortText') }), totalItems > 10 && (_jsx(PaginationContainer, { children: _jsx(Pagination, { onChange: setPage, totalItems: totalItems, currentPage: page, pageSize: 10 }) }))] }));
}
export default UsersTab;
