import { t } from 'i18next';
import parsePhoneNumberFromString, { isPossiblePhoneNumber, isValidPhoneNumber, } from 'libphonenumber-js';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { isEmpty } from 'lodash';
const IBAN_FORMATS = {
    DE: /^DE\d{20}$/,
};
export const validateDate = (date, limit) => {
    const twoYearsAgo = new Date();
    twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - limit);
    return date <= twoYearsAgo;
};
export const validateHasNumbers = (value) => {
    const hasNumber = /\d/;
    return hasNumber.test(value);
};
export const validateFloatNumbers = (value) => /^\d+\.\d+$/.test(value);
export const validateOnlyNumbers = (value) => /^\d+$/.test(value);
export const validatePhoneNumber = (value, countryCode) => {
    try {
        const phoneNumber = parsePhoneNumber(value, countryCode);
        if (typeof phoneNumber !== 'undefined') {
            return isValidPhoneNumber(value, countryCode) && isPossiblePhoneNumber(value);
        }
    }
    catch (error) {
        return false;
    }
};
export const validateIBAN = (iban) => {
    iban = iban.replace(/\s+/g, '').toUpperCase();
    const countryCode = iban.slice(0, 2);
    if (IBAN_FORMATS[countryCode]) {
        if (IBAN_FORMATS.DE.test(iban)) {
            const reversedIBAN = iban.substring(4) + iban.substring(0, 4);
            let ibanNumber = '';
            for (let i = 0; i < reversedIBAN.length; i++) {
                const digit = reversedIBAN.charAt(i);
                if (digit.match(/\d/)) {
                    ibanNumber += digit;
                }
                else {
                    ibanNumber += (digit.charCodeAt(0) - 'A'.charCodeAt(0) + 10).toString();
                }
            }
            if (BigInt(ibanNumber) % 97n === 1n)
                return true;
        }
    }
    return false;
};
export const validateLegalForm = (data) => {
    const excludedLegalForms = ['Ltd', 'UG'];
    if (!isEmpty(data))
        return !excludedLegalForms.includes(data);
    return false;
};
const rulePatterns = {
    email: {
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: t('FormErrors.Email'),
        },
    },
    required: {
        required: t('FormErrors.Required'),
    },
    validamount: {
        validate: (data) => {
            if (typeof data === 'string') {
                return validateFloatNumbers(data) || t('FormErrors.AmountError');
            }
        },
    },
    isnumeric: {
        validate: (data) => {
            if (typeof data === 'string') {
                return validateOnlyNumbers(data) || t('FormErrors.AmountError');
            }
        },
    },
    phone: {
        validate: (phoneData) => {
            if (phoneData.includes('phone')) {
                const { phone } = JSON.parse(phoneData);
                const phoneNumber = parsePhoneNumberFromString(phone);
                if (phoneNumber) {
                    return (validatePhoneNumber(phoneNumber.number, phoneNumber.country) ||
                        t('FormErrors.Phone'));
                }
            }
            else
                return t('FormErrors.Phone');
        },
    },
};
export const generateRules = (types) => {
    const rules = {};
    if (Array.isArray(types))
        types.forEach((element) => Object.assign(rules, rulePatterns[element.toLowerCase()]));
    return rules;
};
