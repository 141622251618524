import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { AreaChart, ChartIndicators, ColoredDotLabel, WhiteBlock } from 'ui-components-web';
import { IndicatorsWrapper } from 'Components/CostsRevenueSection/style';
import theme from 'Global/theme';
import { formatCurrency, formatDate } from 'Global/utils';
import { DotsContainer } from './style';
function LiquidityChart({ overall, status }) {
    const { t } = useTranslation();
    const formmtGraphMonth = (time) => {
        const date = new Date(time);
        const year = date.getFullYear().toString().substring(2);
        const month = t(`Months.${date.getMonth()}`);
        return `${month} ${year}`;
    };
    const renderPercentage = (data) => {
        const d = new Date();
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        const currentDate = `${year}-${month}-15`;
        const result = data
            ? data.find((item) => item.month === currentDate)
            : [];
        return result ? result.percentage_change?.toString() || 'No data' : 'No data';
    };
    return (_jsx(_Fragment, { children: _jsxs(WhiteBlock, { children: [overall.balances.overall && (_jsx(IndicatorsWrapper, { children: _jsx(ChartIndicators, { title: t('CustomerDetails.Liquidity'), amount: formatCurrency(overall.balances.overall.current_liquidity?.current_balance), lastUpdate: `${t('CustomerDetails.LastUpdate')} ${formatDate(overall.balances.overall.current_liquidity?.current_date)}`, status: overall.balances.overall.liquidity_status, percentage: renderPercentage(overall.balances.overall.monthly_data), text: t('CustomerDetails.CurrentMonth') }) })), overall.balances && (_jsx(AreaChart, { data: overall.balances.overall.monthly_data, width: 700, height: 300, formatXItem: formmtGraphMonth, formatYItem: (t) => formatCurrency(t), graphColor: status === 'critical'
                        ? theme.colors.error
                        : status === 'uncritical'
                            ? theme.colors.success
                            : theme.colors.warning })), _jsxs(DotsContainer, { children: [_jsx("div", { style: { paddingRight: '2em' }, children: _jsx(ColoredDotLabel, { fontSize: '17', text: t('CustomerDetails.Liquidity'), color: theme.colors.success }) }), _jsx(ColoredDotLabel, { fontSize: '17', text: t('CustomerDetails.Prognose'), color: 'rgba(0, 181, 120, 0.5)' })] })] }) }));
}
export default LiquidityChart;
