import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import { defaultSort, formatDate } from 'Global/utils';
import { ListContainer, TextRowContent } from './style';
export const Sorter = {
    DEFAULT: defaultSort,
};
const renderPendingInvitationsColumns = () => [
    {
        title: t('InvitationsTable.Customer'),
        dataIndex: ['company_name', 'first_name', 'last_name'],
        key: 'customer',
        render: (_, record) => {
            const { company_name, first_name, last_name } = record;
            return (_jsxs("ul", { style: { listStyleType: 'none', padding: 0 }, children: [_jsx(TextRowContent, { children: company_name }, company_name), _jsxs(TextRowContent, { "$isGray": true, children: [first_name, " ", last_name] }, `${first_name} ${last_name}`)] }));
        },
        sorter: {
            compare: Sorter.DEFAULT,
            multiple: 3,
        },
    },
    {
        title: t('InvitationsTable.ContactData'),
        dataIndex: ['email', 'phone_number'],
        key: 'contact_data',
        render: (_, record) => {
            const { email, phone_number } = record;
            return (_jsxs(ListContainer, { children: [_jsx(TextRowContent, { children: email }, email), _jsx(TextRowContent, { "$isGray": true, children: phone_number }, phone_number)] }));
        },
    },
    {
        title: t('CustomersTable.status'),
        key: 'status',
        responsive: ['md'],
        render: (_, record) => {
            const { status, invited_at } = record;
            return (_jsxs("ul", { style: { listStyleType: 'none', padding: 0 }, children: [_jsx(TextRowContent, { children: t(`InvitationsTable.InvitationStatus.${status}`) }, status), _jsx(TextRowContent, { "$isGray": true, children: formatDate(invited_at || '') }, invited_at)] }));
        },
    },
];
export default renderPendingInvitationsColumns;
