import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CustomEvents } from 'Global/types';
import { trackEvent } from 'Global/utils';
const PageViewTracker = () => {
    const location = useLocation();
    useEffect(() => {
        trackEvent(CustomEvents.PAGE_VIEW);
    }, [location.pathname]);
    return null;
};
export default PageViewTracker;
