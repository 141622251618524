import { jsx as _jsx } from "react/jsx-runtime";
import { ConfigProvider } from 'antd';
import deDE from 'antd/lib/locale/de_DE';
import TagManager from 'react-gtm-module';
import { useForm } from 'react-hook-form';
import { I18nextProvider } from 'react-i18next';
import { ComponentLibThemeProvider } from 'ui-components-web';
import { FormContext } from 'Context/Form/context';
import i18n from 'Global/Locales/i18n';
import { antTheme } from 'Global/antTheme';
import { REACT_APP_GOOGLE_TAG_MANAGER, REACT_APP_GOOGLE_TAG_MANAGER_ID } from 'Global/envVars';
import theme from 'Global/theme';
import MainAppRouter from 'Router/mainRouter';
const App = () => {
    // init google TAG MANAGER
    if (REACT_APP_GOOGLE_TAG_MANAGER && REACT_APP_GOOGLE_TAG_MANAGER_ID) {
        const tagManagerArgs = {
            gtmId: REACT_APP_GOOGLE_TAG_MANAGER_ID,
        };
        TagManager.initialize(tagManagerArgs);
    }
    const { handleSubmit, watch, formState: { errors }, reset, control, setValue, getValues, } = useForm();
    const handleFormSubmit = (action) => handleSubmit(() => action && action)();
    return (_jsx(ComponentLibThemeProvider, { theme: theme, children: _jsx(I18nextProvider, { i18n: i18n, children: _jsx(ConfigProvider, { locale: deDE, theme: antTheme, children: _jsx(FormContext.Provider, { value: {
                        control,
                        watch,
                        reset,
                        setValue,
                        errors,
                        handleSubmit: handleFormSubmit,
                        getValues,
                    }, children: _jsx(MainAppRouter, {}) }) }) }) }));
};
export default App;
