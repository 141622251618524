import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { t } from 'i18next';
import { decodeJwt } from 'jose';
import { isEmpty } from 'lodash';
import TagManager from 'react-gtm-module';
import getIdToken from 'Service/iapAuthorization';
import { REACT_APP_NODE_ENV } from './envVars';
import { CustomEvents, StorageKeys, } from './types';
dayjs.extend(utc);
dayjs.extend(timezone);
export const getTimestamp = () => {
    return dayjs().tz('Europe/Berlin').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
};
export const isProduction = () => REACT_APP_NODE_ENV === 'production';
export const formatDate = (date) => {
    if (date) {
        const newDate = new Date(date);
        const format = 'DD.MM.YYYY';
        const dateResult = dayjs(newDate).format(format);
        return dateResult;
    }
    return '';
};
export const formatCurrency = (value, style = 'currency') => {
    if (!value) {
        value = 0;
    }
    return new Intl.NumberFormat('de-DE', {
        currency: 'EUR',
        style,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(value);
};
export const genders = [
    { label: 'Herr', value: 'Herr' },
    { label: 'Frau', value: 'Frau' },
    { label: 'Andere', value: 'Andere' },
];
export const yesNo = [
    { label: 'Ja', value: 'yes' },
    { label: 'Nein', value: 'no' },
];
export function parseArrayToObject(arr) {
    const obj = {};
    arr.forEach((entry) => {
        const [key, value] = entry;
        if (obj)
            obj[key] = value;
    });
    return obj;
}
export const getCurrentBase = () => {
    if (window.location.pathname.includes('/hub'))
        return '/hub';
    if (window.location.pathname.includes('/pos'))
        return '/pos';
    if (window.location.pathname.includes('/application/v1'))
        return '/application/v1';
    return '/';
};
export const redirectUrl = (url) => {
    if (url) {
        window.location.href = url;
    }
};
export const calculateYearFromCurrent = (numberOfYears) => {
    const currentYear = new Date().getFullYear();
    return (currentYear - numberOfYears).toString();
};
export const formatFloatToCents = (value) => Math.round(parseFloat(value) * 100);
export const searchForElementInList = (list, element) => Array.isArray(list) ? list?.includes(element) : false;
export const renderModalContent = (children) => {
    if (typeof children == 'string') {
        if (!isEmpty(children))
            return children;
        else
            return t('ConfirmModal.description');
    }
    return children;
};
export const getItemFromStorage = (element) => {
    const formValues = localStorage.getItem(element);
    if (formValues) {
        try {
            const parsedFormStorage = JSON.parse(formValues);
            return parsedFormStorage;
        }
        catch (error) {
            return formValues;
        }
    }
    return {};
};
export const setItemInStorage = (element, value) => localStorage.setItem(element, value.toString());
export const removeItemDromStorage = (element) => localStorage.removeItem(element);
export const roundMonthlyRate = (value) => Math.round(Number(value)).toString();
export const formatInterestRate = (value) => (Math.round(Number(value) * 10) / 10).toString().replace('.', ',');
export const handleTab = (index, e, inputRefs) => {
    if (e.key === 'Tab') {
        e.preventDefault();
        const nextIndex = e.shiftKey
            ? index === 0
                ? inputRefs.current.length - 1
                : index - 1
            : index === inputRefs.current.length - 1
                ? 0
                : index + 1;
        inputRefs.current[nextIndex].focus();
    }
};
export const handleAssignRef = (ref, index, inputRefs) => inputRefs && inputRefs.current && (inputRefs.current[index] = ref);
export const extractFile = (data, type, year) => data.filter((item) => typeof year !== 'undefined'
    ? item.document_year === year && item.type === type
    : item.type === type)[0];
export const formatSearchList = (data) => data.map((element) => {
    return { ...element, value: element.legal_entity_name, text: element.legal_entity_name };
});
// Retrieve tenant_id and token
export async function getCredentials() {
    const tenant_id = await getItemFromStorage(StorageKeys.TENANT_ID);
    const token = await getItemFromStorage(StorageKeys.VRSG_SESSION);
    return { tenant_id, token };
}
export const renderPhoneNumber = (number) => {
    if (number && number.includes('phone')) {
        const object = JSON.parse(number);
        return object.phone;
    }
    return number;
};
export const validateDate = (date) => {
    const parsedDate = dayjs(date);
    return parsedDate.isValid();
};
export const isJwtExpired = (token) => {
    const { exp } = decodeJwt(token);
    const now = Math.floor(Date.now() / 1000);
    if (exp)
        return exp < now;
    return true;
};
let tokenPromise = null;
export async function GenerateAuthToken() {
    if (REACT_APP_NODE_ENV === 'k8s-staging') {
        const authToken = getItemFromStorage(StorageKeys.TOKEN);
        if (isEmpty(authToken) || isJwtExpired(authToken)) {
            if (!tokenPromise) {
                tokenPromise = async () => {
                    const result = await getIdToken();
                    setItemInStorage(StorageKeys.TOKEN, result);
                    tokenPromise = null;
                    return result;
                };
            }
            return await tokenPromise();
        }
        else {
            return authToken;
        }
    }
}
export const trackEvent = (event, eventAction = 'Click') => TagManager.dataLayer({
    dataLayer: {
        event: event,
        eventAction: eventAction,
        pagePath: event === CustomEvents.PAGE_VIEW && window.location.pathname,
        pageTitle: event === CustomEvents.PAGE_VIEW && document.title,
    },
});
export const renderSuggestedAction = (text) => {
    switch (text) {
        case 'CONTACT':
            return t('SuggestedAction.Contact');
        case 'OBSERVE':
            return t('SuggestedAction.Observe');
        case 'WAIT':
            return t('SuggestedAction.Wait');
    }
};
export const renderPendingInvitationStatus = (value) => {
    switch (value) {
        case 'NOT_INVITED':
            return t('InvitationStatus.NotInvited');
        case 'INVITED':
            return t('InvitationStatus.Invited');
        case 'ENROLLED':
            return t('InvitationStatus.Enrolled');
    }
};
// Customers list sorter
export const defaultSort = (a, b) => {
    const { company_name } = a;
    if (company_name && b.company_name)
        return company_name.localeCompare(b.company_name);
};
