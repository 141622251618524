import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { CustomEvents } from 'Global/types';
const useTimeOnPage = (pageName) => {
    useEffect(() => {
        const startTime = Date.now();
        const handlePageUnload = () => {
            const endTime = Date.now();
            const timeSpent = Math.round((endTime - startTime) / 1000);
            TagManager.dataLayer({
                dataLayer: {
                    event: CustomEvents.TIME_SPENT_ON_PAGE,
                    pageName: pageName,
                    timeSpent: timeSpent,
                },
            });
        };
        // Track when the page unloads
        window.addEventListener('beforeunload', handlePageUnload);
        return () => {
            handlePageUnload(); // Call when the component unmounts
            window.removeEventListener('beforeunload', handlePageUnload);
        };
    }, [pageName]);
};
export default useTimeOnPage;
