import styled from 'styled-components';
export const MenuHeaderContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 10px;
  padding-inline: 30px;
  background: ${({ theme }) => theme.colors.nightBlue};
`;
export const IconContainer = styled.div `
  cursor: pointer;
`;
