import theme from './theme';
export const antTheme = {
    token: {
        fontFamily: 'GenosGFG Regular',
        colorPrimaryHover: theme.colors.orange,
        colorPrimary: theme.colors.darkOrange,
        colorBorder: '#000000',
    },
    components: {
        Pagination: {
            itemActiveBg: 'transparent',
            itemBg: 'transparent',
            colorBgTextHover: 'transparent',
            colorPrimary: theme.colors.textDefault,
            colorPrimaryHover: theme.colors.textDefault,
            colorPrimaryBorder: 'transparent',
            colorPrimaryBorderHover: 'transparent',
            colorLinkHover: 'green',
            colorLink: theme.colors.textDefault,
        },
        Select: {
            activeBorderColor: theme.colors.primary,
            hoverBorderColor: theme.colors.primary,
            optionSelectedBg: `#1E90E82B`,
        },
    },
};
