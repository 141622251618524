import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import React from 'react';
import { Icon } from 'ui-components-web';
import { IconSet } from 'ui-components-web/src/Components/Icon';
import CustomModal from 'ui-components-web/src/Components/Modal';
import { Label } from 'ui-components-web/src/Global/styled';
import theme from 'Global/theme';
import { Container, EmailContent, EmailLink, PhoneContent } from './style';
function ContactUsModal({ isOpen, setIsOpen }) {
    return (_jsx(CustomModal, { titleColor: theme.colors.primary, title: 'VR Smart Guide Support', handleCancel: () => setIsOpen(false), isModalOpen: isOpen, children: _jsxs("div", { style: { textAlign: 'start' }, children: [t('ContactUsModal.title'), _jsxs(Container, { children: [_jsxs(EmailContent, { children: [_jsx(Icon, { iconName: IconSet.envelope, iconSize: '22', color: theme.colors.primary }), _jsx(EmailLink, { href: 'mailto:partnersupport@vrsg.de', style: { paddingLeft: '10px' }, children: "partnersupport@vrsg.de" })] }), _jsxs(PhoneContent, { children: [_jsx(Icon, { iconName: IconSet.phone, iconSize: '16', color: theme.colors.primary }), _jsx(Label, { style: { paddingLeft: '10px' }, children: " +49 6196 7853237" })] })] })] }) }));
}
export default ContactUsModal;
