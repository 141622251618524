import { Pagination as AntPagination } from 'antd';
import styled from 'styled-components';
export const StyledPagination = styled(AntPagination) `
  .ant-pagination-item a[rel='nofollow'] {
    color: ${({ theme }) => theme.colors.textDisabled};
  }
  .ant-pagination-item-active {
    border: 0 !important;
  }
  .ant-pagination-item-active a[rel='nofollow'] {
    color: ${({ theme }) => theme.colors.textDefault};
  }
  .ant-pagination-disabled {
    color: ${({ theme }) => theme.colors.textDisabled};
  }
`;
