import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { t } from 'i18next';
import { useMemo, useState } from 'react';
import { RowBlockWithTag } from 'ui-components-web';
import { v4 as uuidv4 } from 'uuid';
import { PanelHeading } from 'Global/styled';
import theme from 'Global/theme';
import { RecomendationContent, ShowMoreLessButton } from './style';
function RecomendationsList({ items }) {
    const [showMoreRecommended, setShowMoreRecommended] = useState(false);
    const [showMoreAvailable, setShowMoreAvailable] = useState(false);
    const recommended = useMemo(() => {
        return items.filter((item) => parseFloat(item.affinity_score) >= 50);
    }, [showMoreRecommended, items]);
    const available = useMemo(() => {
        return items.filter((item) => parseFloat(item.affinity_score) < 50);
    }, [showMoreAvailable, items]);
    const renderRow = (items) => items.map((row) => (_jsx(RowBlockWithTag, { product: t(`ProductRecommendations.${row.product}.title`), product_type: t(`ProductRecommendations.${row.product}.sub_category`) }, uuidv4())));
    return (_jsxs(_Fragment, { children: [_jsxs("div", { style: { marginBottom: '2rem' }, children: [_jsx(PanelHeading, { children: t('CustomerDetails.ProductRecommendation') }), _jsx(RecomendationContent, { style: { marginTop: '1rem' }, children: recommended.length ? (renderRow(recommended.slice(0, showMoreRecommended ? undefined : 3))) : (_jsx("div", { style: { color: theme.colors.textDefault, fontSize: '1rem' }, children: t('CustomerDetails.NoProductRecommendation') })) }), recommended.length > 3 && (_jsx(ShowMoreLessButton, { onClick: () => setShowMoreRecommended(!showMoreRecommended), children: showMoreRecommended ? t('CustomerDetails.ShowLess') : t('CustomerDetails.ShowMore') }))] }), available.length ? (_jsxs("div", { style: { marginBottom: '1rem' }, children: [_jsx(PanelHeading, { children: t('CustomerDetails.AdditionalProducts') }), _jsx(RecomendationContent, { style: { marginTop: '1rem' }, children: renderRow(available.slice(0, showMoreAvailable ? undefined : 3)) }), available.length > 3 && (_jsx(ShowMoreLessButton, { onClick: () => setShowMoreAvailable(!showMoreAvailable), children: showMoreAvailable ? t('CustomerDetails.ShowLess') : t('CustomerDetails.ShowMore') }))] })) : null] }));
}
export default RecomendationsList;
