import styled from 'styled-components';
export const RecomendationContent = styled.div `
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const ShowMoreLessButton = styled.button `
  margin: 2rem 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  width: 100%;
`;
