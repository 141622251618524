export var RULES;
(function (RULES) {
    RULES["EMAIL"] = "EMAIL";
    RULES["VALIDAMOUNT"] = "VALIDAMOUNT";
    RULES["ISNUMERIC"] = "ISNUMERIC";
    RULES["PHONE"] = "PHONE";
    RULES["REQUIRED"] = "REQUIRED";
})(RULES || (RULES = {}));
export var FormState;
(function (FormState) {
    FormState["OPEN"] = "open";
    FormState["DRAFT"] = "draft";
    FormState["DONE"] = "done";
})(FormState || (FormState = {}));
export var StorageKeys;
(function (StorageKeys) {
    StorageKeys["CREDIT_FORM"] = "creditform";
    StorageKeys["CAR_FINANCING"] = "car_financing";
    StorageKeys["IS_EXPRESS"] = "is_express";
    StorageKeys["CREFO_SCORE"] = "crefo_score";
    StorageKeys["LOAN_AMOUNT"] = "loan_amount";
    StorageKeys["CREDIT_APPLICATION_ID"] = "credit_application_id";
    StorageKeys["TENANT_ID"] = "tenant_id";
    StorageKeys["VRSG_SESSION"] = "vrsg_session";
    StorageKeys["ACCOUNTING_DOCUMENTS"] = "accounting_documents";
    StorageKeys["OBJECT_DOCUMENTS"] = "object_documents";
    StorageKeys["FLOW"] = "flow";
    StorageKeys["TAX_PROVIDED"] = "tax_advisor_provided";
    StorageKeys["PARTNER_NAME"] = "partner_name";
    StorageKeys["LANDING_SCREEN_MOBILE"] = "landing_screen_mobile";
    StorageKeys["PARTNER_BACK_URL"] = "partner_back_url";
    StorageKeys["TOKEN"] = "token";
})(StorageKeys || (StorageKeys = {}));
export var FLOWS;
(function (FLOWS) {
    FLOWS["MAIN"] = "main";
    FLOWS["MOBILE"] = "mobile";
    FLOWS["PARTNER"] = "partner";
    FLOWS["POS"] = "pos";
})(FLOWS || (FLOWS = {}));
export var DeviderOrientation;
(function (DeviderOrientation) {
    DeviderOrientation["CENTER"] = "center";
    DeviderOrientation["LEFT"] = "left";
    DeviderOrientation["RIGHT"] = "right";
})(DeviderOrientation || (DeviderOrientation = {}));
export var DocumentTypes;
(function (DocumentTypes) {
    DocumentTypes["BWA"] = "BWA";
    DocumentTypes["SUSALI"] = "SUSALI";
    DocumentTypes["EUR"] = "EUR";
    DocumentTypes["ANNUAL_FINANCIAL_REPORT"] = "ANNUAL_FINANCIAL_REPORT";
    DocumentTypes["OBJECT_DOCUMENT"] = "OBJECT_DOCUMENT";
})(DocumentTypes || (DocumentTypes = {}));
export const ActionOptions = [
    { label: 'Nicht erreicht', value: 'NOT_REACHED' },
    { label: 'Offen', value: 'OPEN' },
    { label: 'Erreicht', value: 'CONTACTED' },
];
export const FilterOptions = [
    { label: 'Alle Kunden', value: 'All customers' },
    { label: 'Registrierung abgeschlossen', value: 'registered' },
    { label: 'Antrag begonnen', value: 'application_started' },
    { label: 'Antrag abgeschlossen', value: 'applied_product' },
    { label: 'Wartet auf Entscheidung', value: 'product_desision_pending' },
    { label: 'Antrag angenommen', value: 'approved' },
    { label: 'Antrag abgelehnt', value: 'rejected' },
];
export var FinCheckStatus;
(function (FinCheckStatus) {
    FinCheckStatus["registration_incomplete"] = "registration_incomplete";
    FinCheckStatus["registration_complete"] = "registration_complete";
    FinCheckStatus["application_started"] = "application_started";
    FinCheckStatus["application_complete"] = "application_complete";
    FinCheckStatus["waiting_for_decision"] = "waiting_for_decision";
    FinCheckStatus["application_accepted"] = "application_accepted";
    FinCheckStatus["application_denied"] = "application_denied";
})(FinCheckStatus || (FinCheckStatus = {}));
export var CustomEvents;
(function (CustomEvents) {
    CustomEvents["SUBMIT_LOGIN_SUCCESS"] = "login_submit_form_success";
    CustomEvents["SUBMIT_LOGIN_FAILED"] = "login_submit_form_failed";
    CustomEvents["CONTACT_SUPPORT_LINK_CLICK"] = "contact_support_link_click";
    CustomEvents["PAGE_VIEW"] = "page_view";
    CustomEvents["ADD_NEW_COMPANY_CLICK"] = "add_new_company_click";
    CustomEvents["SEARCH_COMPANY_INPUT_CHANGED"] = "search_company_input_changed";
    CustomEvents["SEARCH_COMPANY_SELECTED_OPTION"] = "search_company_selected_option";
    CustomEvents["SUBMIT_INVITE_PORTAL"] = "submit_invite_portal";
    CustomEvents["BURGER_MENU_CLICK"] = "burger_menu_click";
    CustomEvents["BACK_TO_OVERVIEW_MENU_OPTION"] = "back_to_overview_menu_option";
    CustomEvents["LOGOUT_MENU_OPTION"] = "logout_menu_option";
    CustomEvents["CONTACT_SUPPORT_MENU_OPTION"] = "contact_support_menu_option";
    CustomEvents["SEARCH_COMPANY_IN_LIST_INPUT"] = "search_company_in_list_input";
    CustomEvents["INVITE_PORTAL_BTN_CLICK"] = "invite_portal_btn_click";
    CustomEvents["BACK_ICON_CUSTOMER_DETAILS"] = "back_icon_customer_details";
    CustomEvents["PRODUCT_RECOMENDATION_ROW_CLICK"] = "product_recomendation_row_click";
    CustomEvents["TIME_SPENT_ON_PAGE"] = "time_spent_on_page";
    CustomEvents["LAST_ACTION_DROP_DOWN_UPDATED"] = "last_action_drop_down_updated";
})(CustomEvents || (CustomEvents = {}));
export var PendingInvitationStatusTypes;
(function (PendingInvitationStatusTypes) {
    PendingInvitationStatusTypes["NOT_INVITED"] = "NOT_INVITED";
    PendingInvitationStatusTypes["INVITED"] = "INVITED";
    PendingInvitationStatusTypes["ENROLLED"] = "ENROLLED";
})(PendingInvitationStatusTypes || (PendingInvitationStatusTypes = {}));
