import { createContext } from 'react';
export const FormContext = createContext({
    control: undefined,
    watch: undefined,
    reset: undefined,
    setValue: undefined,
    errors: undefined,
    handleSubmit: undefined,
    getValues: undefined,
});
