import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import CustomDropDown from 'ui-components-web/src/Components/CustomDropDown';
import { ActionOptions, CustomEvents, } from 'Global/types';
import { defaultSort, formatDate, renderSuggestedAction, trackEvent } from 'Global/utils';
import { updateCustomerStatus } from 'Service/api/finInsights';
import { ListContainer, TextRowContent } from './style';
export const Sorter = {
    DEFAULT: defaultSort,
};
const handleUpdateStatus = (customer_id, value) => updateCustomerStatus(customer_id, value.value)
    .then((result) => {
    if (result)
        trackEvent(CustomEvents.LAST_ACTION_DROP_DOWN_UPDATED);
})
    .catch((error) => console.log({ error }));
const renderFinCheckUsersColumns = () => [
    {
        title: t('CustomersTable.Customers'),
        dataIndex: 'company_name',
        key: 'company_name',
        render: (text, record) => {
            const { owner_name, account_number } = record;
            return (_jsxs("ul", { style: { listStyleType: 'none', padding: 0 }, children: [_jsx(TextRowContent, { children: text }, text), _jsx(TextRowContent, { "$isGray": true, children: owner_name }, owner_name), _jsxs(TextRowContent, { "$isGray": true, children: ["AN - ", account_number] }, account_number)] }));
        },
        sorter: {
            compare: Sorter.DEFAULT,
            multiple: 3,
        },
    },
    {
        title: 'FinCheck Status',
        dataIndex: 'fincheck_status',
        key: 'fincheck_status',
        responsive: ['lg'],
        render: (status, record) => {
            const { fincheck_status_date } = record;
            return (_jsxs(ListContainer, { children: [_jsx(TextRowContent, { children: t(`OverviewPage.FinCheckStatus.${status}`, { defaultValue: status }) }, status), _jsx(TextRowContent, { "$isGray": true, children: formatDate(fincheck_status_date) }, fincheck_status_date)] }));
        },
    },
    {
        title: t('CustomersTable.ProductRecommendation'),
        dataIndex: 'product_recommendations',
        key: 'product_recommendations',
        responsive: ['lg'],
        render: (record) => (_jsx(ListContainer, { children: record?.length > 0 &&
                record
                    .sort((a, b) => b.affinity_score - a.affinity_score)
                    .filter((item) => item.affinity_score >= 50)
                    .slice(0, 3)
                    .map((item) => (_jsx(TextRowContent, { children: t(`ProductRecommendations.${item.product}.title`) }, item.product))) })),
    },
    {
        title: t('CustomersTable.RecommendedAction'),
        key: 'recommended_action',
        dataIndex: 'recommended_action',
        responsive: ['lg'],
        render: (text, record) => {
            const { recommended_action_date } = record;
            return (_jsxs(ListContainer, { children: [_jsx(TextRowContent, { children: renderSuggestedAction(text) }, text), _jsx(TextRowContent, { "$isGray": true, children: recommended_action_date && recommended_action_date.split('T')[0] }, recommended_action_date)] }));
        },
    },
    {
        title: t('CustomersTable.status'),
        key: 'action',
        render: (text, record) => {
            const { last_action_taken } = record;
            return (_jsx("div", { "data-test-id": 'action-drop-down', style: { width: '10em' }, onClick: (e) => e.stopPropagation(), children: _jsx(CustomDropDown, { width: '100%', defaultValue: last_action_taken, options: ActionOptions, placeholder: 'Aktion', callback: (value) => record?.sme_tenant_id && handleUpdateStatus(record?.sme_tenant_id, value) }) }));
        },
    },
];
export default renderFinCheckUsersColumns;
