import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import ContactUsModal from 'Components/ContactUsModal';
import Header from 'Components/Header';
import { ContentContainer } from './style';
function Layout({ children }) {
    const [isOpen, setIsOpen] = useState(false);
    return (_jsxs("div", { children: [_jsx(Header, { setModalOpen: () => setIsOpen(true) }), _jsx(ContentContainer, { children: children }), _jsx(ContactUsModal, { isOpen: isOpen, setIsOpen: setIsOpen })] }));
}
export default Layout;
