export const REACT_APP_NODE_ENV = process.env.REACT_APP_NODE_ENV;
export const REACT_APP_METADATA_HOST = process.env.REACT_APP_METADATA_HOST;
export const REACT_APP_METADATA_INFORMATION_HOST = process.env.REACT_APP_METADATA_INFORMATION_HOST;
export const REACT_APP_TENANT_HOST = process.env.REACT_APP_TENANT_HOST;
export const REACT_APP_AUTH_HOST = process.env.REACT_APP_AUTH_HOST;
export const REACT_APP_DOCUMENT_HOST = process.env.REACT_APP_DOCUMENT_HOST;
export const REACT_APP_HOST = process.env.REACT_APP_HOST;
export const REACT_APP_COOKIES_FIRST = process.env.REACT_APP_COOKIES_FIRST;
export const REACT_APP_GOOGLE_TAG_MANAGER = process.env.REACT_APP_GOOGLE_TAG_MANAGER;
export const REACT_APP_GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
export const REACT_APP_PAYMENT_HOST = process.env.REACT_APP_PAYMENT_HOST;
export const REACT_APP_IAP_TOKEN = process.env.REACT_APP_IAP_TOKEN;
