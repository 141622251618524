import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { REACT_APP_AUTH_HOST, REACT_APP_NODE_ENV } from 'Global/envVars';
import { getCredentials, redirectUrl } from 'Global/utils';
import ROUTES from 'Router/routes';
import getIdToken from 'Service/iapAuthorization';
const finInsights = axios.create({
    baseURL: REACT_APP_AUTH_HOST,
});
finInsights.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response && error.response.status === StatusCodes.UNAUTHORIZED) {
        redirectUrl(ROUTES.LOGIN);
    }
    return Promise.reject(error);
});
finInsights.interceptors.request.use(async (config) => {
    try {
        // Retrieve tenant_id and token
        const { token } = await getCredentials();
        // Set headers with retrieved tenant_id and token
        if (token)
            config.headers['X-Session-Cookie'] = token;
        if (REACT_APP_NODE_ENV !== 'production')
            config.headers['Authorization'] = await getIdToken();
    }
    catch (error) {
        // Handle error while fetching token
        console.error('Error fetching token:', error);
    }
    return config;
});
export default finInsights;
