import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row } from 'antd';
import { t } from 'i18next';
import InputField from 'ui-components-web/src/Components/Input/InputField';
import InputPhone from 'ui-components-web/src/Components/Input/InputPhone';
import { generateRules } from 'Global/rules';
import { Container } from 'Global/styled';
import { RULES } from 'Global/types';
import { handleAssignRef } from 'Global/utils';
const CompanyInfoStep = ({ control, disabled, errors, inputRefs, isAddCompany = false, }) => {
    return (_jsxs(Container, { "data-test-id": 'CompanyInfoStep', children: [isAddCompany && (_jsx(Row, { children: _jsx(InputField, { disabled: disabled, ref: (ref) => handleAssignRef(ref, 1, inputRefs), errors: errors, control: control, name: 'companyName', label: t('InvitePortalPage.CompanyName'), rules: generateRules([RULES.REQUIRED]) }) })), _jsx(Row, { children: _jsx(InputField, { disabled: disabled, ref: (ref) => handleAssignRef(ref, 1, inputRefs), errors: errors, control: control, name: 'firstName', label: t('InvitePortalPage.FirstName'), rules: generateRules([RULES.REQUIRED]) }) }), _jsx(Row, { children: _jsx(InputField, { disabled: disabled, ref: (ref) => handleAssignRef(ref, 2, inputRefs), errors: errors, control: control, name: 'lastName', label: t('InvitePortalPage.LastName'), rules: generateRules([RULES.REQUIRED]) }) }), _jsx(Row, { children: _jsx(InputPhone, { disabled: disabled, control: control, ref: (ref) => handleAssignRef(ref, 4, inputRefs), name: 'phoneInput', label: t('InvitePortalPage.PhoneNumber'), rules: generateRules([RULES.REQUIRED, RULES.PHONE]) }) }), _jsx(Row, { children: _jsx(InputField, { disabled: disabled, ref: (ref) => handleAssignRef(ref, 5, inputRefs), control: control, name: 'email', label: t('InvitePortalPage.EmailAddress'), errors: errors, rules: generateRules([RULES.REQUIRED, RULES.EMAIL]) }) })] }));
};
export default CompanyInfoStep;
