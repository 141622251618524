import styled from 'styled-components';
import { mediaQueries } from 'Global/styled';
export const CustomerDescriptionContainer = styled.div `
  display: flex;
  flex-direction: column;
  padding: 1rem;
  ${mediaQueries.md} {
    flex-direction: row;
  }
  gap: 1rem;
`;
export const DetailColumn = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
`;
export const DetailLabel = styled.div `
  font-size: 13px;
  padding-bottom: 5px;
`;
