import styled from 'styled-components';
export const CustomerDetailsHeaderContainer = styled.div `
  display: flex;
  flex-direction: row;
  padding-bottom: 2em;
  align-items: ${({ $description }) => ($description ? null : 'center')};
`;
export const IconContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const BackIcon = styled.div `
  cursor: pointer;
  height: 2.4em;
  width: 2.5em;
  background-color: ${({ theme }) => theme.colors.blueChambray};
  display: inline-block;
  border-radius: 10px;
`;
