import styled from 'styled-components';
export const Container = styled.div `
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const EmailContent = styled.div `
  padding-top: 20px;
  display: flex;
  flex-direction: row;
`;
export const EmailLink = styled.a `
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  text-decoration: underline;
`;
export const PhoneContent = styled.div `
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: start;
`;
